import {
  DateNameValue,
  HistoricalResultValue,
  NameValue,
  ReportValue,
  ResultGroups,
} from '@codenteam/portal/graphql';
import { MultiLinesGraphInterface } from '@codenteam/ui/multi-lines-graph/multi-lines-graph.dto';
import { BehaviorSubject } from 'rxjs';
type Group = 'profile' | 'team' | 'organization' | 'ex';

export class Utils {
  static getUrlHost() {
    const fullUrl: string = window.location.href;
    const splitUrl: string[] = fullUrl.split('/');
    const urlHost = splitUrl[2];
    return urlHost;
  }

  static resolveHistoricalValue(
    historical: ReportValue,
    group: Group,
    when: 'total' | 'month',
    id: number
  ): HistoricalResultValue {
    try {
      const nameValue = historical.code[when][group].find(
        (item) => item.id === id
      );
      if (nameValue && nameValue.value) {
        return nameValue;
      } else {
        return { id, name: 'New', value: 0, color: '#000' };
      }
    } catch {
      return {
        id,
        name: 'New',
        value: 0,
        color: '#000',
      };
    }
  }
  //convert date from Seconds to yyyy-mm-dd
  static convertSecondsToDateFormat(secondsDate: number): string {
    const date = new Date(secondsDate * 1000);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    return `${year}-${month}-${day}`;
  }

  static findHighestValue(data: NameValue[]): NameValue {
    return data.reduce((acc, cur) => (acc.value > cur.value ? acc : cur));
  }
  static assignEmptyDaysToZeroValue(
    data: DateNameValue[],
    range: { start: number; end: number }
  ): DateNameValue[] {
    const fromDate = range.start;
    const toDate = range.end;

    const convertedData: DateNameValue[] = [];

    //to get each team name and id from the response

    const groupObjects: { id: number; name: string }[] = [];

    const groupIds = [...new Set(data.map((item) => item.id))];

    for (const id of groupIds) {
      const name = data.find((item) => item.id === id)?.name;

      if (name) {
        const teamObject = { id, name };
        groupObjects.push(teamObject);
      }
    }

    //loop for each team
    for (const id of groupIds) {
      //loop for each day in the given range
      for (
        let date = fromDate;
        new Date(date * 1000) <= new Date(toDate * 1000);
        date += 86400
      ) {
        const dayForTeam = data.find((res) => {
          return (
            res.date === Utils.convertSecondsToDateFormat(date) && res.id === id
          );
        });
        if (dayForTeam) {
          convertedData.push(dayForTeam);
        } else {
          const zeroDataForDay = {
            id: id,
            name: groupObjects.find((item) => item.id === id).name,
            date: Utils.convertSecondsToDateFormat(date),
            value: 0,
          };
          convertedData.push(zeroDataForDay);
        }
      }
    }
    return convertedData;
  }

  static convertDateNameValueToMultiline(data: DateNameValue[]) {
    const groupedData = data.reduce(
      (acc: { [teamName: string]: MultiLinesGraphInterface }, curr) => {
        const date = curr.date;
        const teamName = curr.name;
        const value = curr.value;
        const color = curr.color;
        if (!acc[teamName]) {
          acc[teamName] = {
            key: teamName,
            values: [],
          };
        }

        acc[teamName].values.push({
          team: teamName,
          quantity: value,
          date: date,
          color: color,
        });

        return acc;
      },
      {}
    );
    return Object.values(groupedData);
  }

  static calculateTotalForNameValue(data: NameValue[]) {
    return data.reduce((acc, cur) => acc + cur.value, 0);
  }

  static calculatePercentageForNameValue(data: NameValue[]): NameValue[] {
    const total =
      data?.length === 1
        ? data[0]?.value
        : Utils.calculateTotalForNameValue(data);
    return data.map((item) => {
      return {
        id: item.id,
        name: item.name,
        value: Math.round((item.value / total) * 100),
        color: item.color,
      };
    });
  }

  static calculateTotalForExNameValue(data: NameValue[]) {
    return data.reduce((acc, cur) => acc + cur.value, 0);
  }

  static calculatePercentageForExNameValue(data: NameValue[]): NameValue[] {
    const total = Utils.calculateTotalForExNameValue(data);
    return data.map((item) => {
      let name;
      let color;
      if (item.name === 'false') {
        name = 'Current';
        color = '#0284C7';
      } else if (item.name === 'true') {
        name = 'Ex employees';
        color = '#FF0000';
      }

      return {
        name: name,
        value: Math.round((item.value / total) * 100),
        color: color,
      };
    });
  }

  static getDefaultDateRange() {
    const now = new Date();
    now.setUTCHours(0, 0, 0, 0);
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setUTCHours(0, 0, 0, 0);
    thirtyDaysAgo.setDate(now.getDate() - Utils.getDefaultDateRangeDays());
    const start = Math.floor(thirtyDaysAgo.getTime() / 1000);
    const end = Math.floor(now.getTime() / 1000);
    return {
      start,
      end,
    };
  }
  // Part of rxjs pipe
  static ifAllZeroEmitEmptyArray(data: NameValue[]) {
    const total = Utils.calculateTotalForNameValue(data);
    if (total === 0) {
      return [];
    }
    return data;
  }
  static last14Days() {
    const now = new Date();
    now.setUTCHours(0, 0, 0, 0);
    const fourteenDaysAgo = new Date();
    fourteenDaysAgo.setUTCHours(0, 0, 0, 0);
    fourteenDaysAgo.setDate(now.getDate() - 14);
    const start = Math.floor(fourteenDaysAgo.getTime() / 1000);
    const end = Math.floor(now.getTime() / 1000);
    return {
      start,
      end,
    };
  }

  static shiftDateRangeByDays(
    range: { start: number; end: number },
    days: number
  ) {
    const start = range.start + days * 86400;
    const end = range.end + days * 86400;
    return {
      start,
      end,
    };
  }
  static getDefaultDateRangeDays() {
    return 30;
  }
  static getThisMonthDateRange() {
    const now = new Date();
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(now.getDate() - 30);
    const start = Math.floor(thirtyDaysAgo.getTime() / 1000);
    const end = Math.floor(now.getTime() / 1000);
    return {
      start,
      end,
    };
  }

  static dateRangeBehaviorSubject(range?: { start: number; end: number }) {
    if (range) {
      return new BehaviorSubject<{ start: number; end: number }>(range);
    }
    return new BehaviorSubject<{ start: number; end: number }>(
      Utils.getDefaultDateRange()
    );
  }
  static getLastMonthDateRange() {
    const now = new Date();
    const thirtyDayAgo = new Date();
    const sixtyDaysAgo = new Date();
    thirtyDayAgo.setDate(now.getDate() - 30);
    sixtyDaysAgo.setDate(now.getDate() - 60);
    const start = Math.floor(sixtyDaysAgo.getTime() / 1000);
    const end = Math.floor(thirtyDayAgo.getTime() / 1000);
    return {
      start,
      end,
    };
  }

  static omitTypeName<T>(r: T & { __typename?: string }) {
    const { __typename, ...rest } = r;
    return rest;
  }
}
